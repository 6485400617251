import axios from "axios";
import { v4 } from "uuid";
export interface iSwishInput {
  recipient: string;
  swishData: iSwishData;
}
export interface iSwishData {
  transaction_reference: string;
  payer_phone?: string;
  amount: number;
  message: string;
}

interface iReturn {
  prId: string;
}

export const createPaymentRequest = async (
  inData: iSwishInput
): Promise<string> => {
  // TODO: interface
  const prId = v4().replaceAll("-", "").toLocaleUpperCase();
  console.log(prId);
  try {
    const res = await axios.post(
      `https://api.dev.getplinka.com/swish/${inData.recipient}/payment-request/${prId}`,
      inData.swishData
    );
    const { data }: any = res;

    console.log("PR DATA: ", data.data);
    return data.data;
  } catch (error) {
    return "Crap";
  }
};

export const getQrCodeFromToken = async (token: string) => {
  try {
    const response = await axios.get(
      `https://api.dev.getplinka.com/swish/qr/${token}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
