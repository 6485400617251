import dayjs from "dayjs";
import type { NextPage } from "next";
import { useRouter } from "next/dist/client/router";
import React, { useState } from "react";
import { Button, Logo } from "../../components/atoms";
import { Layout } from "../../components/layout";
import { useAppContext } from "../../context/appcontext";
import { createPaymentRequest, iSwishInput } from "../../lib/swish";
import { formatter } from "../../lib/utils";

const OrderPage: NextPage = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);
  const [qrCode, setQrCode] = useState<any>();

  const { orderId } = router.query;
  const { isMobile } = useAppContext();

  const handleClick = async (qr = false) => {
    let data: iSwishInput = {
      recipient: order.payment_receipent,
      swishData: {
        amount: totalAmount,
        transaction_reference: order.transaction_reference,
        message: "",
        // message: `Tack för ditt köp [organization]`,
      },
    };

    if (!qr) {
      data.swishData.payer_phone = order.delivery_information.phone.replace(
        /^0+/,
        "46"
      );
    }

    setPaying(true);

    createPaymentRequest(data)
      .then(async (res) => {
        console.log("Got back:", res);

        if (isMobile) {
          // TODO: handle mobile
          document.location.href = `swish://paymentrequest?token=${res}&callbackurl=http%3A%2F%2F192.168.86.25%2F373737`; // &callbackurl=merchant%253A%252F%252F
        } else {
          // TODO: handle QR flow
          // const qrcode = await getQrCodeFromToken(res);
          setQrCode(res);
        }
      })
      .finally(() => {
        setPaying(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const order = {
    organisation: {
      name: "Plinka IF",
    },
    order: {
      created: "2021-12-02, 11:32",
    },
    id: "21cb7929-6f07-45ff-81c0-c37a5b219c61",
    pos_id: "fa3c59f4-52ea-4d71-9da1-f5f83db04c21",
    organization_id: "cb33a238-b44b-4c02-8e02-7e4e590cb0b8",
    user_id: "c57c3f29-c451-42eb-b546-59a28ee77952",
    basket_id: "00000000-0000-0000-0000-000000000000",
    payment_method: "swish",
    amount_paied: 0,
    payment_reference: "05ad7112-1720-4e2d-aa98-83a7eb62c1b0",
    created: "2021-11-17T15:51:29.6654289+00:00",
    receipt_number: 7,
    items: [
      {
        id: "40bdf688-43e1-4cdf-a5c4-1e0eca17eb63",
        price_per_item: 12.5,
        quantity: 2.0,
        item: {
          id: "05ad7112-1720-4e2d-aa98-83a7eb62c1b0",
          price_incl_vat: 12.0,
          product_number: "0123-456-A",
        },
        vat_amount: 0.0,
        vat_percentage: 0.0,
        payment_status: "open",
        delivery_status: "closed",
        line_number: 10000,
        delivered_quantity: 0.0,
        invoiced_quantity: 0.0,
      },
    ],
    payment_receipent: "1235337779",
    transaction_reference: "LTwWwsB",
    number_of_items: 2,
    transaction_direction: "sale",
    status: "preorder",
    session_id: "3c19e909-bb7d-4ea2-b27e-0aff1f4afab5",
    payment_status: "open",
    delivery_status: "open",
    delivery_information: {
      name: "Michael Hansson",
      address1: "Kungsgatan 20",
      zip: "97234",
      city: "LULEA",
      phone: "0727324442",
      notes: "Lämna på bron om ingen är hemma.",
    },
    vat_summary: [
      {
        id: "00000000-0000-0000-0000-000000000000",
        amount: 25.0,
        vat_amount: 0.0,
        vat_rate: { id: "00000000-0000-0000-0000-000000000000", rate: 0.0 },
      },
    ],
  };

  let totalAmount = -order.amount_paied;

  order.vat_summary.map((vs) => {
    totalAmount += vs.amount;
  });

  return (
    <Layout>
      <div className="flex flex-col bg-white">
        <div className="flex flex-row justify-between items-center">
          <Logo small center={false} />
          <div className="text-xl">#{orderId}</div>
        </div>
        {/* Org info */}
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div>Sålt av:</div>
            <div>{order.organisation.name}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>Datum:</div>
            <div>{dayjs(order.created).format("YYYY-MM-DD hh:mm")}</div>
          </div>
          {/* Kundinfo */}
          <div className="text-lg mt-6">
            <h2>Kundinfo</h2>
          </div>
          <div className="flex flex-row justify-between">
            <div>
              <div className="flex flex-row justify-between">
                {/* <div>Namn:</div> */}
                <div>{order.delivery_information.name}</div>
              </div>
              <div className="flex flex-row justify-between">
                {/* <div>Adress:</div> */}
                <div>{order.delivery_information.address1}</div>
              </div>
              <div className="flex flex-row justify-between">
                {/* <div>Postkod:</div> */}
                <div>{order.delivery_information.zip}</div>
              </div>
              <div className="flex flex-row justify-between">
                {/* <div>Ort:</div> */}
                <div>{order.delivery_information.city}</div>
              </div>
            </div>
            <div>
              <div className="flex flex-col mt-2">
                <div>Telefon:</div>
                <div>{order.delivery_information.phone}</div>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col mt-2">
            <div>Anteckningar:</div>
            <div className="italic">{order.delivery_information.notes}</div>
          </div> */}
          {/* Orderinfo */}
          <div className="text-lg mt-6">
            <h2>Orderinfo</h2>
          </div>
          {order.items.map((item) => (
            <div key={item.id} className="flex flex-row justify-between">
              <div>
                {item.quantity}x {item.item.product_number}
              </div>
              <div>{formatter.format(item.price_per_item)}</div>
            </div>
          ))}
          {/* Summary */}
          <div className="flex flex-row justify-between mt-6 text-xl">
            <div>Totalt:</div>
            {/* //TODO: proper total amount */}
            <div className="font-bold">{formatter.format(totalAmount)}</div>
          </div>
          {order.vat_summary.map((vs) => {
            if (vs.vat_rate.rate === 0) return null;

            return (
              <div key={vs.id} className="flex flex-col mt-2">
                <div>Varav moms ({vs.vat_rate.rate}):</div>
                <div className="italic">{vs.vat_amount}</div>
              </div>
            );
          })}
          {/* Button */}
          <div className="text-center mt-4">
            <div className="text-xs mb-6 w-3/4 mx-auto">
              {isMobile ? (
                <div>
                  Att klicka generar en betalning i Swish och öppnar sen din
                  Swish-app. Klicka här för att istället visa en QR-kod om det
                  inte är telefonnummret ovan som ska betala.
                </div>
              ) : (
                <div>
                  Att klicka generar en betalning i Swish och visar en QR-kod
                  som du kan scanna av med din Swish-app.
                </div>
              )}
            </div>
            {qrCode === undefined ? (
              <>
                <Button onClick={() => handleClick(false)}>Öppna Swish</Button>
                <Button onClick={() => handleClick(true)}>
                  Betala med Swish
                </Button>
              </>
            ) : (
              <img src={`https://api.dev.getplinka.com/swish/qr/${qrCode}`} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderPage;
